body {
  margin: 0;
  font-family: Muli;
  background-color: #f4f4f4;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Muli";
  src: url("fonts/muli-bold-webfont.woff2") format("woff2"),
    url("fonts/muli-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Muli";
  src: url("fonts/muli-extrabold-webfont.woff2") format("woff2"),
    url("fonts/muli-extrabold-webfont.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Muli";
  src: url("fonts/muli-bolditalic-webfont.woff2") format("woff2"),
    url("fonts/muli-bolditalic-webfont.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Muli";
  src: url("fonts/muli-blackitalic-webfont.woff2") format("woff2"),
    url("fonts/muli-blackitalic-webfont.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Muli";
  src: url("fonts/muli-semibolditalic-webfont.woff2") format("woff2"),
    url("fonts/muli-semibolditalic-webfont.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Muli";
  src: url("fonts/muli-semibold-webfont.woff2") format("woff2"),
    url("fonts/muli-semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Muli";
  src: url("fonts/muli-regular-webfont.woff2") format("woff2"),
    url("fonts/muli-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Muli";
  src: url("fonts/muli-lightitalic-webfont.woff2") format("woff2"),
    url("fonts/muli-lightitalic-webfont.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Muli";
  src: url("fonts/muli-light-webfont.woff2") format("woff2"),
    url("fonts/muli-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Muli";
  src: url("fonts/muli-italic-webfont.woff2") format("woff2"),
    url("fonts/muli-italic-webfont.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Muli";
  src: url("fonts/muli-extralightitalic-webfont.woff2") format("woff2"),
    url("fonts/muli-extralightitalic-webfont.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Muli";
  src: url("fonts/muli-extralight-webfont.woff2") format("woff2"),
    url("fonts/muli-extralight-webfont.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Muli";
  src: url("fonts/muli-extrabolditalic-webfont.woff2") format("woff2"),
    url("fonts/muli-extrabolditalic-webfont.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Vodafone";
  font-style: normal;
  font-weight: normal;
  src: url("fonts/VodafoneRg.eot");
  src: local("?"), url("fonts/VodafoneRg.woff") format("woff"),
    url("fonts/VodafoneRg.ttf") format("truetype"),
    url("fonts/VodafoneRg.svg") format("svg");
}

@font-face {
  font-family: "Vodafone";
  font-style: normal;
  font-weight: bold;
  src: url("fonts/VodafoneRgBd.eot");
  src: local("?"), url("fonts/VodafoneRgBd.woff") format("woff"),
    url("fonts/VodafoneRgBd.ttf") format("truetype"),
    url("fonts/VodafoneRgBd.svg") format("svg");
}

@font-face {
  font-family: "Vodafone";
  font-style: normal;
  font-weight: bolder;
  src: url("fonts/VodafoneExBd.eot");
  src: local("?"), url("fonts/VodafoneExBd.woff") format("woff"),
    url("fonts/VodafoneExBd.ttf") format("truetype"),
    url("fonts/VodafoneExBd.svg") format("svg");
}

@font-face {
  font-family: "Vodafone";
  font-style: normal;
  font-weight: lighter;
  src: url("fonts/VodafoneLt.eot");
  src: local("?"), url("fonts/VodafoneLt.woff") format("woff"),
    url("fonts/VodafoneLt.ttf") format("truetype"),
    url("fonts/VodafoneLt.svg") format("svg");
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Vodafone";
    font-style: normal;
    font-weight: normal;
    src: url("fonts/VodafoneRg.svg") format("svg");
  }

  @font-face {
    font-family: "Vodafone";
    font-style: normal;
    font-weight: bold;
    src: url("fonts/VodafoneRgBd.svg") format("svg");
  }
  @font-face {
    font-family: "Vodafone";
    font-style: normal;
    font-weight: bolder;
    src: url("fonts/VodafoneExBd.svg") format("svg");
  }
  @font-face {
    font-family: "Vodafone";
    font-style: normal;
    font-weight: lighter;
    src: url("fonts/VodafoneLt.svg") format("svg");
  }
}

@font-face {
  font-family: "Vodafone";
  font-style: normal;
  src: url("fonts/vodafrgreg-webfont.eot");
  src: local("?"), url("fonts/vodafrgreg-webfont.woff") format("woff"),
    url("fonts/vodafrgreg-webfont.ttf") format("truetype"),
    url("fonts/vodafrgreg-webfont.svg") format("svg");
}
